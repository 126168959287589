<template>
  <div>
    <el-row class="accountCon">
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 案例信息
          <img src="@/assets/images/triangle.png" alt />
        </div>
      </el-col>
      <el-col class="rightCon" :span="20">
        <el-form
          ref="detail"
          label-position="right"
          label-width="140px"
          :rules="rules"
          :model="detail"
        >
          <el-form-item label="客户名称:" prop="sequence">
            <el-select v-model="detail.market" class="w300" clearable placeholder="请选择客户名称">
              <el-option
                v-for="item in options.market"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="责任护理师:" prop="sequence">
            <el-select v-model="detail.market" class="w300" clearable placeholder="请选择责任护理师">
              <el-option
                v-for="item in options.market"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="疤痕原因:" prop="sequence">
            <el-input v-model="detail.sequence" class="w300" maxlength="150" show-word-limit />
          </el-form-item>
          <el-form-item label="疤痕类型:" prop="sequence">
            <el-select v-model="detail.market" class="w300" clearable placeholder="请选择疤痕类型">
              <el-option
                v-for="item in options.market"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="上传图片:" prop="sequence">
            <el-upload
              class="avatar-uploader"
              :action="website.uploaderUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="detail.imageUrl" :src="detail.imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="申请位置:" prop="sequence">
            <el-select v-model="detail.market" class="w300" clearable placeholder="请选择申请位置">
              <el-option
                v-for="item in options.market"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="治疗位置:" prop="sequence">
            <el-input type="textarea" v-model="detail.market"></el-input>
          </el-form-item>
          <el-button type="primary" class="mainBtn" @click="submitForm('detail')">确定</el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
class Detail {
  sequence = "";
  sex = 1;
  telephone = "";
  market = "";
  startData = "";
  imageUrl = "";
}
class Rules {
  sequence = [{ required: true, message: "请填写部门序号", trigger: "blur" }];
  telephone = [
    { required: true, message: "请填写联系电话", trigger: "blur" },
    {
      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
      message: "手机号格式不对",
      trigger: "change"
    }
  ];
  startData = [{ required: true, message: "请选择日期", trigger: "change" }];
}
class Options {
  constructor() {
    this.market = [
      { id: 0, name: "全部" },
      { id: 1, name: "选项1" },
      { id: 2, name: "选项2" }
    ]; // 代理人
  }
}
export default {
  props: {},

  data() {
    return {
      detail: new Detail(),
      rules: new Rules(),
      options: new Options(), // 选项
      active: 0
    };
  },
  computed: {},
  watch: {
    selectedBtn(val) {}
  },
  mounted() {},
  methods: {
    // 下一页
    next() {
      this.active += 1;
    },
    // 上一页
    up() {
      this.active -= 1;
    },
    // 提交
    submitForm(formName) {
      this.$emit("setActive", true);
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      this.detail.imageUrl = URL.createObjectURL(file.raw);
    },
    // 上传图片拦截
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;
  .leftCon {
    background: #fcfcfc;
    border-right: 1px solid #eee;
    position: absolute;
    height: 100%;
    > div {
      line-height: 48px;
      color: #fff;
      background: #9ea7b4;
      width: 100%;
      padding-left: 80px;
      font-size: 14px;
      box-sizing: border-box;
      img {
        position: absolute;
        right: -30px;
        width: 40px;
        height: 48px;
      }
    }
    .label1 {
      position: absolute;
      top: 40px;
    }
    .label2 {
      position: absolute;
      top: 270px;
    }
  }
  .rightCon {
    padding: 40px 0 0 500px;
    .btn {
      margin: 20px 0 50px 80px;
    }
    .mainBtn {
      margin: 30px 0 0 140px;
      padding: 8px 40px;
    }
    .phoneTip {
      font-size: 12px;
      color: #999;
    }
  }
}
::v-deep .avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-upload:hover {
    border-color: $theme-color;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
</style>
